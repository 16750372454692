import React from 'react';
import {FaGithub, FaLinkedin} from 'react-icons/fa';
import {HiOutlineMail} from 'react-icons/hi';
import {BsFillPersonLinesFill} from 'react-icons/bs';

const SocialLinks = () => {
    const links= [
        {
            id:1,
            child: (
                <>
                LinkedIn <FaLinkedin size={30}/>
            </>
            ),
            href: 'https://www.linkedin.com/in/farhan-rahman-8415a9214/',
            style: 'rounded-tr-md'
        },
        {
            id:2,
            child: (
                <>
                GitHub <FaGithub size={30}/>
            </>
            ),
            href: 'https://github.com/akib1010'
        },
        {
            id:3,
            child: (
                <>
                Mail <HiOutlineMail size={30}/>
            </>
            ),
            href: 'mailto:akib1010@gmail.com'
        },
        {
            id:4,
            child: (
                <>
                Resume <BsFillPersonLinesFill size={30}/>
            </>
            ),
            href: '/FarhanRahman_Resume.docx',
            style: 'rounded-br-md',
            download: true
        }
    ]


  return (
    <div className="hidden lg:flex flex-col top-[35%] left-0 fixed">
        <ul>

            {links.map(({id,child,href,style,download}) => (
            // eslint-disable-next-line no-useless-concat
            <li key={id} className={"flex justify-between items-center w-40 h-14 px-4 bg-gradient-to-r from-gray-600 to-gray-800 ml-[-100px] hover:ml-[-10px] hover:rounded-md duration-300 " + " " + style}>
                <a className="flex justify-between items-center w-full text-white" href={href} download={download} target="_blank" rel="noreferrer">
                    {child}
                </a>
            </li>
            ))}

        </ul>
    </div>
  )
}

export default SocialLinks