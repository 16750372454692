import React, { useState } from 'react';
import {FaBars, FaTimes} from "react-icons/fa";
import {Link} from "react-scroll";

const NavBar = () => {
    const [nav, setNav]=useState(false);


    const links=[
        {
            id: 1,
            link:'Home'
        },
        {
            id: 2,
            link:'About'
        },
        {
            id: 3,
            link:'Projects'
        },
        {
            id: 4,
            link: 'Technical'
        },
        {
            id: 5,
            link:'Contact'
        }
    ]


  return (
    <div className="flex justify-between items-center w-full h-20 px-4 text-white bg-black fixed">
        <div>
            <h1 className="text-3xl font-signature ml-2">Farhan Rahman</h1>
        </div>

        <ul className="hidden md:flex">

            {links.map(({id,link}) => (
                <li key={id} className="px-4 cursor-pointer font-medium text-gray-500 hover:scale-105 duration-200">
                    <Link to={link} smooth duration={700}>{link}</Link>
                    </li>
            ))}
        </ul>


        <div onClick={()=> setNav(!nav)} className="cursor-pointer pr-4 z-10 text-gray-500 md:hidden">
            {nav? <FaTimes size={30}/> : <FaBars size={30}/>}
        </div>

        {nav && (
        <ul className="flex flex-col justify-center items-center absolute top-0 left-0 w-full h-screen bg-gradient-to-b from-black to-gray-800 text-4xl">
        {links.map(({id,link}) => (
                <li key={id} className="px-4 cursor-pointer py-6 text-4xl text-gray-500">
                    <Link onClick={() => setNav(!nav)} to={link} smooth duration={700}>{link}</Link>
                </li>
            ))}
        </ul>
        )}

    </div>
    
  )
}

export default NavBar